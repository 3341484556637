import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	CardActionArea,
	CardActions,
	CardMedia,
	Button,
	Card,
	Checkbox
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { viewImage } from '../../../../../../../store/file/actions';
import { openSnackbar } from '../../../../../../../store/snackbar/actions';

const useStyles = makeStyles({
	root: {
		width: '100%'
	},
	media: {
		height: 200
	}
});

function Image(props) {
	const classes = useStyles();
	const { image, t, selector, selectItem } = props;
	const [selected, setSelected] = useState(false);

	const handleChange = (event) => {
		setSelected(event.target.checked);
		selectItem(image);
	};

	return (
		<Card
			className={classes.root}
			onClick={() => {
				selector ? selectItem(image) : viewImage(image);
			}}>
			<CardActionArea>
				<CardMedia
					className={classes.media}
					image={`${image.url}?size=sm`}
					title='Contemplative Reptile'
				/>
			</CardActionArea>
			{selector && (
				<Checkbox
					checked={selected}
					onChange={handleChange}
					name='selected'
					color='primary'
				/>
			)}
			{!selector && (
				<CardActions>
					<CopyToClipboard
						text={image.url}
						onCopy={() =>
							openSnackbar({
								message: 'Masolva a vagolapra!',
								severity: 'success'
							})
						}>
						<Button size='small' color='primary'>
							{t('common:copy')}
						</Button>
					</CopyToClipboard>
				</CardActions>
			)}
		</Card>
	);
}

export default withNamespaces()(Image);
