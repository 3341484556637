import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles, Box, Grid, Typography, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Image as Img } from '../../../../../../../components/image';

const useStyles = makeStyles((theme) => ({
	root: {
		maxHeight: 313,
		height: '100%',
		overflow: 'hidden',
		// backgroundColor: "white",
		margin: 8,
		'&:hover': {
			cursor: 'pointer'
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 30
		}
	},
	titleBox: {
		backgroundColor: '#070C35',
		height: '39px',
		position: 'absolute',
		//  marginTop: "215px",
		marginLeft: -theme.spacing(1),
		paddingTop: '18px',
		paddingLeft: '27px',
		paddingRight: '27px',
		marginTop: -80
	},
	titleText: {
		color: theme.palette.primary.contrastText,
		textAlign: 'center',
		verticalAlign: 'middle',
		fontWeight: 'bold',
		fontSize: '18px'
	},
	img: {
		maxHeight: '313px',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			maxHeight: '270px'
		}
	},
	loaderContainer: {
		height: '313px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'gray'
	}
}));

function SightCard(props) {
	const { item, lang } = props;
	const classes = useStyles();
	const [localizedHotelItem, setLocalizedHotelItem] = useState(null);

	const content = item.content.filter((data) => data.lang === lang)[0];
	const [imgReady, setImgReady] = useState(false);

	useEffect(() => {
		setImgReady(false)
		if(content.headerImage.url){
			const img = new Image();
			img.onload = () => {
				setImgReady(true);
			}
			img.src = content.headerImage.url + "?size=md";
			img.alt = content.headerImage.id;
		}
	}, [content]);
	

	const navigateToNews = () => {
		window.scrollTo(0, 0);

		props.history.push(`/sight/${item.id}`);
	};
	if (item)
		return (
			<Grid item xs={12} md={6}>
				<Box className={classes.root} onClick={() => navigateToNews()}>
					<Box className={classes.img}>
						{imgReady ?
							<Img image={content.headerImage.url} size='md' />
						:
							<Box className={classes.loaderContainer} >
								<CircularProgress color='secondary'/>
							</Box>
						}
					</Box>
					<Box className={classes.titleBox}>
						<Typography className={classes.titleText}>
							{content.title}
						</Typography>
					</Box>
				</Box>
			</Grid>
		);
}

export default withNamespaces('sight')(withRouter(SightCard));
