import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Image } from '../../../components/image';
import { ImageResults, FileResults } from './components/Results';
import { openSnackbar } from '../../../store/snackbar/actions';
import { FILE_TYPE } from '../../../store/types';

const File = (props) => {
	const { t, type } = props;
	const image = useSelector((state) => state.file.view);
	return (
		<>
			{type === FILE_TYPE.IMAGE && (
				<Grid container spacing={4}>
					<Grid item md={image && image.url ? 6 : 12}>
						<ImageResults />
					</Grid>
					{image && image.url && (
						<Grid item md={6}>
							<Image image={image} size='lg' />
							<CopyToClipboard
								text={image.url}
								onCopy={() =>
									openSnackbar({
										message: 'Masolva a vagolapra!',
										severity: 'success'
									})
								}>
								<Button size='small' color='primary'>
									{t('common:copy')}
								</Button>
							</CopyToClipboard>
						</Grid>
					)}
				</Grid>
			)}
			{type === FILE_TYPE.ATTACHMENT && (
				<Grid container spacing={4}>
					<Grid item md={12}>
						<FileResults />
					</Grid>
				</Grid>
			)}
		</>
	);
};
export default withNamespaces()(File);
