import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	Typography,
	Grid,
	Link,
	Box,
	useMediaQuery,
	Divider
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { SocialIcon } from './social/icons';
import { TouchesLogo, OfficeLogo } from '../../icons';
import { DateInfo } from './components/DateInfo';
import { FooterNavButton } from './components/NavButton';
import navItems from './navItems';
import LanguageSelector from '../../language/LanguageSelector';
import navItemsVisit from './navItemsVisit';

let page
const useStyles = makeStyles((theme) => {
	return ({
	root: {
		minHeight: 492,
		backgroundColor: theme.palette.primary.dark,
		width: '100%'
	},
	internalGrid: {
		maxWidth: '1129px',
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: theme.spacing(4, 0),
		"& *" : {
			zIndex: 300
		}
	},
	box: {
		display: 'flex',
		flexDirection: 'column'
		// height: "100%",
	},
	boxFullHeight: { padding: theme.spacing(4, 3) },
	
	boxReducedHeight: { marginTop: page == 'office' ? 152 : 60, paddingLeft: theme.spacing(3) },
	divider: {
		backgroundColor: '#F0F0EB',
		color: '#F0F0EB',
		width: '100%',
		height: 1,
		margin: theme.spacing(3, 0)
	},
	title: {
		color: '#F0F0EB',
		fontSize: '22px',
		letterSpacing: '1.1px',
		textTransform: 'uppercase',
		fontFamily: 'Bebas Neue',
		fontWeight: 'bold',
		marginBottom: 21
	},
	icons: {
		display: 'flex',
		justifyContent: 'start'
	},
	text: {
		color: '#F0F0EB',
		fontSize: 14,
		fontFamily: 'Ubuntu',
		marginTop: 8,
		display: 'flex'
	},
	bold: {
		color: '#F0F0EB',
		fontSize: 14,
		fontFamily: 'Ubuntu',
		display: 'flex',
		fontWeight: '700'
	}
})});

const Footer = (props) => {
	const { t, page } = props;
	const theme = useTheme();
	const classes = useStyles([theme , page]);
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const showFooter = useSelector((state) => state.layout.showFooter);
	const role = useSelector((state) => state.me.role);

	return (
		showFooter && !role && (
			<div className={classes.root}>
				<Grid container spacing={0} className={classes.internalGrid}>
					<Grid item xs={12} md={3}>
						<Box
							className={clsx(
								classes.box,
								isMobile ? classes.boxFullHeight : {}
							)}>
							{page == 'office' ? <OfficeLogo fill='#FFFFFF' height='70px' /> :
							<TouchesLogo fill='#FFFFFF' width='240'/>}
							<Divider className={classes.divider} />
							<DateInfo />
							<Divider className={classes.divider} />
							<Typography className={classes.title}>{t('language')}</Typography>
							<LanguageSelector />
						</Box>
					</Grid>
					{page == "lives" ?
						<>
						<Grid item xs={12} md={3}>
							<Box
								className={clsx(
									classes.box,
									isMobile ? classes.boxFullHeight : classes.boxReducedHeight
								)}>
								<Typography className={classes.title}>{t('visit-siculeni')}</Typography>
								{navItemsVisit.one.map((item) => (
									<FooterNavButton
										key={item.name}
										href={item.href}
										name={t(item.name)}
										goToLivePage={item.goToLivePage}
										page = {page}
									/>
								))}
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box
								className={clsx(
									classes.box,
									isMobile ? classes.boxFullHeight : classes.boxReducedHeight
								)}>
								<Link className={classes.title} href="https://madefalva.ro/">{t('to-siculeni')}</Link>
							</Box>
						</Grid>
					</>
					: <>
						<Grid item xs={12} md={3}>
							<Box
								className={clsx(
									classes.box,
									isMobile ? classes.boxFullHeight : classes.boxReducedHeight
								)}>
								<Typography className={classes.title}>{t('siculeni')}</Typography>
								{navItems.one.map((item) => (
									<FooterNavButton
										key={item.name}
										href={item.href}
										name={t(item.name)}
										goToLivePage={item.goToLivePage}
										page = {page}
									/>
								))}
							</Box>
						</Grid>
						<Grid item xs={12} md={3}>
							<Box
								className={clsx(
									classes.box,
									isMobile ? classes.boxFullHeight : classes.boxReducedHeight
								)}>
								<Typography className={classes.title}>
									{t('announcement')}
								</Typography>
								{navItems.two.map((item) => (
									<FooterNavButton
										key={item.name}
										href={item.href}
										name={t(`common:${item.name}`)}
										goToLivePage={item.goToLivePage}
									/>
								))}
							</Box>
						</Grid>
						<Grid item xs={12} md={3}>
							<Box
								className={clsx(
									classes.box,
									isMobile ? classes.boxFullHeight : classes.boxReducedHeight
								)}>
								<Typography className={classes.title}>
									{t('office-page')}
								</Typography>
								{navItems.three.map((item) => (
									<FooterNavButton
										key={item.name}
										href={item.href}
										name={t(`council:${item.name}`)}
										goToLivePage={item.goToLivePage}
									/>
								))}
							</Box>
						</Grid>
					</>
					}
					<Divider className={classes.divider} />
					<Grid item xs={12} md={3}>
						<Box className={clsx(classes.box, classes.boxFullHeight)}>
							<Typography className={classes.title}>
								{t('stay-close')}
							</Typography>
							<Box className={classes.icons}>
								<SocialIcon type='fb' />
								<SocialIcon type='ig' />
								<SocialIcon type='yt' />
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} md={3}>
						<Box className={clsx(classes.box, classes.boxFullHeight)}>
							<Typography className={classes.title}>{t('contacts')}</Typography>
							<Typography className={classes.text}>
								{`${t('address')}:`}&#160;
								<Typography className={classes.bold}>
									Siculeni 106, 537295, HR.
								</Typography>
							</Typography>
							<Typography className={classes.text}>
								{`${t('phone')}:`}&#160;
								<Typography className={classes.bold}>0266 379107</Typography>
							</Typography>
							<Typography className={classes.text}>
								{`${t('fax')}:`}&#160;
								<Typography className={classes.bold}>0266 316951</Typography>
							</Typography>
							<Typography className={classes.text}>
								{`${t('email')}:`}&#160;
								<Typography className={classes.bold}>
									madefalva@yahoo.com
								</Typography>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={3}>
						<Box className={clsx(classes.box, classes.boxFullHeight)}>
							<Typography className={classes.title}>
								{t('openingHours')}
							</Typography>
							<Typography className={classes.text}>
								{`${t('monday')} - ${t('thursday')}:`}&#160;
								<Typography className={classes.bold}>8-16</Typography>
							</Typography>
							<Typography className={classes.text}>
								{`${t('friday')}:`}&#160;
								<Typography className={classes.bold}>8-15</Typography>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={3}>
						<Box className={clsx(classes.box, classes.boxFullHeight)}>
							<Typography className={classes.title}>
								{t('auditionHours')}
							</Typography>
							<Typography className={classes.text}>
								Szentes Csaba:&#160;
								<Typography className={classes.bold}>{`${t(
									'tuesday'
								)} 10-11`}</Typography>
							</Typography>
							<Typography className={classes.text}>
								Szőcs László:&#160;
								<Typography className={classes.bold}>{`${t(
									'thursday'
								)} 14-15`}</Typography>
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</div>
		)
	);
};
export default withNamespaces(['navigation', 'common', 'council'])(Footer);
