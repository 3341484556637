import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import { FileSelector } from '../../../components/modals';
import { Select, TextField } from '../../../components/form';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw'
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px'
	}
}));

export default withNamespaces(['common', 'gazette'])((props) => {
	const { open, setOpen, onConfirm, t } = props;

	const classes = useStyles();
	return (
		<Formik
			initialValues={{
				category: 'unit_status',
				content: [
					{
						lang: 'ro'
					}
				]
			}}
			onSubmit={(
				values,
				{ setSubmitting, setErrors, setStatus, resetForm }
			) => {
				onConfirm(values).then((result) => {
					if (result) {
						resetForm({});
						setOpen(false);
					}
				});
			}}>
			{({
				values,
				errors,
				touched,
				handleReset,
				setFieldValue,
				submitForm
			}) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>
						{t('newelementGazette')}
					</DialogTitle>
					<DialogContent>
						<Form>
							<Field
								name='category'
								label={t('category')}
								options={[
									{
										value: 'unit_status',
										label: t('unit_status')
									},
									{
										value: 'admi_procs',
										label: t('admi_procs')
									},
									{
										value: 'auth_dec',
										label: t('auth_dec')
									},
									{
										value: 'ex_disp',
										label: t('ex_disp')
									},
									{
										value: 'fin_docs',
										label: t('fin_docs')
									},
									{
										value: 'report',
										label: t('report')
									},
									{
										value: 'other',
										label: t('other')
									},
									{
										value: 'careers',
										label: t('careers')
									},
									{
										value: 'transparency',
										label: t('transparency')
									},
									{
										value: 'council_meetings',
										label: t('council_meetings')
									},
									{
										value: 'decision_plans',
										label: t('decision_plans')
									}
								]}
								component={Select}
								size='small'
								value={values.category}
								onChange={(evt) => setFieldValue('category', evt.target.value)}
							/>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((item, index) => (
											<Box key={index}>
												<Field
													name={`content[${index}].description`}
													label={t('createGazetteDesc')}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].fileName`}
													label={t('createGazetteFilename')}
													component={TextField}
													multiline
													rows={2}
												/>
												<FileSelector
													fieldName={`content[${index}].fileId`}
													setFileId={(id) => {
														setFieldValue(`content[${index}].fileId`, id);
													}}
												/>
											</Box>
										))}
									</Box>
								)}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='secondary'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='default'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
