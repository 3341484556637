import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Button } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../components/images/vintage04.png';

import {
	ChevronRight,
	StraightStripes,
	HomeScreenHalfCircle,
	Church2,
} from '../../../../components/icons';
import { livePage } from '../../../../config/locations';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover',
	},
	imageContainerFixed: {
		backgroundColor: '#F0F0EB',
		position: 'relative',
		left: '-50px',
		width: '630px',
		height: '475px',
		top: '89px',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 250,
			left: 0,
		},
	},

	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.only('lg')]: {
			top: 0,
		},
		[theme.breakpoints.only('xl')]: {
			top: 100,
		},
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%',
		},
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
	},
	contentContainer: {
		top: '0px',
		width: '100%',
		height: '760px',
		position: 'absolute',
	},
	mainTitleBox: {
		top: '287px',
		position: 'absolute',
		background: '#FEFEFE 0% 0% no-repeat padding-box',
		right: 0,
		left: 'auto',
		textAlign: 'right',
		zIndex: 101,
	},
	mainTitleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 700,
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '56px',
		letterSpacing: '1px',
		marginTop: '6px',
		marginRight: '37px',
		marginBottom: '8px',
		marginLeft: '20px',
		[theme.breakpoints.only('xs')]: {
			fontSize: '36px',
			lineHeight: '40px',
		},
	},
	halfCircle: {
		position: 'absolute',
		top: '352px',
		left: 'auto',
		right: '48px',
		zIndex: 95,
		width: 192,
		height: 96,
		[theme.breakpoints.only('xs')]: {
			width: 150,
			height: 75,
		},
	},
	halfStripes: {
		top: '196px',
		right: '0px',
		left: 'auto',
		position: 'absolute',
		zIndex: 100,
		width: 400,
		height: 252,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
		},
	},
	descriptionContainer: {
		position: 'absolute',
		top: '491px',
		left: 340,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			left: 0,
		},
	},
	descriptionTextContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row-reverse',
		boxSizing: 'border-box',
		paddingRight: '10px',
	},
	descriptionText: {
		textAlign: 'right',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		width: 390,
		paddingTop: '15px',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 30,
			paddingRight: 20,
			width: 300,
			// right: 0,
		},
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		transition: '0.1s all ease',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 40,
			paddingRight: 40,
		},
		'&:hover': {
			backgroundColor: '#48B3C9',
			paddingRight: 70,
			paddingLeft: 70,
			marginRight: -10,
		},
	},
	buttonContainer: {
		position: 'absolute',
		top: '591px',
		left: 'auto',
		right: '77px',
		[theme.breakpoints.only('xs')]: {
			top: 670,
			right: 30,
		},
		[theme.breakpoints.only('sm')]: {
			top: 670,
		},
	},
	footerIconBox: {
		position: 'absolute',
		top: 552,
		left: 0,
		height: '208px',
		width: '300px',
		[theme.breakpoints.only('xs')]: {
			height: 120,
			left: -50,
			top: 625,
		},
	},
	descriptionTitle: {
		color: '#E0B080',
		fontSize: '30px',
		letterSpacing: '2px',
		lineHeight: '30px',
		textTransform: 'uppercase',
		font: 'Bold 30px/30px Bebas Neue',
		textAlign: 'right',
		[theme.breakpoints.only('xs')]: {
			paddingRight: 30,
		},
	},
	ahref: {
		textDecoration: 'none',
	},
}));

function SiculicidiumHistory(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		// window.scrollTo(0, 0);
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box
			className={classes.pageContainer}
			style={{ zIndex: showAnimation ? 1 : 0 }}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='vintageimage3'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrmiddle'
				unmountOnExit>
				<Box className={classes.contentContainer}>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('madefalvahistory2.title')}
						</Typography>
					</Box>
					<Box className={classes.animatedCircles}>
						<Box className={classes.halfStripes}>
							<StraightStripes width='100%' height='100%' />
						</Box>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle width='100%' height='100%' fill='#E0B080' />
						</Box>
					</Box>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionTitle}>
							{t('madefalvahistory2.descriptiontitle')}
						</Typography>
						<Box className={classes.descriptionTextContainer}>
							<Typography
								className={classes.descriptionText}
								varinat='paragraph'>
								<Trans i18nKey='madefalvahistory2.description'>
									<br />
									<br />
								</Trans>
							</Typography>
						</Box>
					</Box>
					<Box className={classes.buttonContainer}>
						<a
							target='_blank'
							href={`${livePage}/history/madefalva`}
							className={classes.ahref}
							rel='noreferrer'>
							<Button type='button' className={classes.nextButton}>
								<Typography variant='button'>
									{t('madefalvahistory2.button')}
								</Typography>
								<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
							</Button>
						</a>
					</Box>
					<Box className={classes.footerIconBox}>
						<Church2
							height='100%'
							width='100%'
							fill='#48B3C9'
							dotfill='#48B3C9'
						/>
					</Box>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('home')(SiculicidiumHistory);
