import React, { useEffect, useState } from 'react';
import {
	Container,
	makeStyles,
	Box,
	Typography,
	withWidth,
	CircularProgress
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { withNamespaces, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Header } from './components/Header';
import { SightCategories } from '../../../config/sight-categories.enum';

import { getSights } from '../../../store/sights/actions';
import { getSight } from '../../../store/sights/sight/actions';
import { Results } from './components/Results';
import { Village } from '../../../components/icons';

const useStyles = makeStyles((theme) => ({
	root: {},
	footer: {
		marginTop: '100vw'
	},
	title: {
		marginTop: '55px',
		color: '#070C35',
		fontSize: '32px',
		fontWeight: 900,
		fontFamily: 'Gilroy',
		paddingLeft: 16,
		paddingRight: 16,
		boxSizing: 'border-box'
	},
	subTitle: {
		marginTop: '25px',
		color: '#070C35',
		fontSize: '14px',
		fontWeight: 'bold',
		marginBottom: '40px',
		width: 520,
		paddingLeft: 16,
		paddingRight: 16,
		boxSizing: 'border-box',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	footelVillage: {},
	loaderContainer: {
		height: '60vh',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

const Sights = (props) => {
	const classes = useStyles();
	const { t } = props;
	const params = useParams();
	const list = useSelector((state) => state.sights.list);
	// const element = useSelector((state) => state.sights.element);
	const lang = useSelector((state) => state.language.language);
	const [ready, setReady] = useState(30);

	useEffect(() => {
		if (Object.keys(SightCategories).includes(params.type)) {
			getSights(params.type);
		} else {
			getSight(params.type);
		}
	}, [params]);
	useEffect(() => {
		setReady(ready + 30);
	}, [list]);

	return (
		<>
			<Container className={classes.root}>
				<Box>
					<Typography className={classes.title}>
						{t(`title${params.type}`)}
					</Typography>
				</Box>
				<Box>
					<Typography className={classes.subTitle}>
						<Trans i18nKey={`subtitle${params.type}`}>
							<br />
							<br />
						</Trans>
					</Typography>
				</Box>
				<Header />
				{ready > 60 ? (
					list.items && <Results list={list} lang={lang} />
				) : (
					<Box className={classes.loaderContainer}>
						<CircularProgress
							color='secondary'
							variant='determinate'
							value={ready}
						/>
					</Box>
				)}
			</Container>
			<Box className={classes.footelVillage}>
				<Village />
			</Box>
		</>
	);
};

export default withWidth()(withNamespaces('sight')(Sights));
