export default {
	root: {
		background: '#070C35 0% 0% no-repeat padding-box',
		borderRadius: '30px',
		height: '60px',
		opacity: 1,
		width: 'fit-content',
		display: 'inline-flex',
		'&hover': {
			backgroundColor: '#48B3C9 0% 0% no-repeat padding-box'
		}
	},
	disabled: {}
};
