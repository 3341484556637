import React, { useState, useEffect } from 'react';
import { Box, makeStyles, CircularProgress } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Slider } from './components/Slider';
import { getSights } from '../../../store/sights/actions';
import Slider11 from '../../../components/images/Slider 1 1.jpg';
import Slider12 from '../../../components/images/Slider 1 2.jpg';
import Slider13 from '../../../components/images/Slider 1 3.jpg';
import Slider14 from '../../../components/images/Slider 1 4.jpg';
import Slider15 from '../../../components/images/Slider 1 5.jpg';
//import Slider16 from '../../../components/images/Slider 1 6.jpg';

import Slider21 from '../../../components/images/Slider 2 1.jpg';
import Slider22 from '../../../components/images/Slider 2 2.jpg';
import Slider23 from '../../../components/images/Slider 2 3.jpg';
import Slider24 from '../../../components/images/Slider 2 4.jpg';
import Slider25 from '../../../components/images/Slider 2 5.jpg';
//import Slider26 from '../../../components/images/Slider 2 6.jpg';

import SliderPic2 from '../../../components/images/Rectangle7857-1.png';
import { OnionFest } from './components/OninFest';
import { Together } from './components/Together';
import { Results } from '../Sights/components/Results';
import { SightCategories } from '../../../config/sight-categories.enum';
import { Village } from '../../../components/icons';


const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%'
	},
	resultsContainer: {
		maxWidth: 1156,
		right: 0,
		left: 0,
		marginRight: 'auto',
		marginLeft: 'auto',
		paddingBottom: 50,
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	relative: {
		height: 400
	},
	loaderContainer: {
		height: '60vh',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

function Lives(props) {
	const classes = useStyles();

	const slider1 = [Slider11, Slider12, Slider13, Slider14, Slider15];
	const slider2 = [Slider21, Slider22, Slider23, Slider24, Slider25];

	const list = useSelector((state) => state.sights.list);
	const [ready, setReady] = useState(30);
	const lang = useSelector((state) => state.language.language);

	useEffect(() => {
		getSights(SightCategories.lives);
	}, []);
	
	useEffect(() => {
	  setReady(ready+30);
	
	}, [list]);
	

	return (
		<Box className={classes.pageContainer}>
			<Slider slider={slider1} />
			<Box className={classes.resultsContainer}>
				{ready>60 ?
					list.items && <Results list={list} lang={lang} />
				:
					<Box className={classes.loaderContainer} >
						<CircularProgress color='secondary' variant="determinate" value={ready}   />
					</Box>
				}
			</Box>

			<Slider slider={slider2} />

			<OnionFest />
			<Together />
			<Village />
		</Box>
	);
}

export default withNamespaces('lives')(Lives);
