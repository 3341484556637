import React from 'react';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { Header } from './components/header';
import { Village2 } from '../../../components/icons';
import { CategoryCard } from './components/Category';
import Image from '../../../components/images/polgarmester2.png';
import Breadcrumbs from '../../../components/Breadcrumbs';

const useStyles = makeStyles((theme) => ({
	hotelTitle: {
		color: '#070C35',
		fontSize: '32px',
		fontWeight: 'bold',
		marginLeft: '365px',
		marginTop: '65px',
		marginBottom: '45px'
	},
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30,
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	titleContainer: {
		width: '100%',
		height: 25,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 40,
		borderBottom: '1px solid #D5D4C9'
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px',
		paddingBottom: 50
	},
	churchCategoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 89,
		paddingLeft: 10,
		paddingBottom: 50
	},
	imageContainer: {
		textAlign: 'center',
		width: '100%'
	},
	desciptionContainer: {
		display: 'border-box',
		//paddingLeft: 40,
		marginLeft: 20,
		marginRight: 30,
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
			padding: 30,
			margin: 0
		},
		[theme.breakpoints.up('md')]: {
			paddingLeft: 20
		}
	},
	content: {
		display: 'inline-flex',
		[theme.breakpoints.down('sm')]: {
			maxWidth: 500
		}
	},
	descriptionTitle: {
		fontFamily: 'Ubuntu',
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '34px'
	},
	descriptionTitleContainer: {
		paddingBottom: 39
	},
	descriptionText: {
		fontFamily: 'Ubuntu',
		lineHeight: '24px',
		fontSize: '14px',
		flexWrap: 'wrap',
		fontWeight: 'bold'
	},
	cardsContainer: {
		paddingTop: 40
	},
	village2Container: {
		paddingTop: '40px'
	},
	img: {
		maxWidth: 264,
		maxHeight: 264,
		marginLeft: 'auto',
		marginRight: 'auto'
	}
}));

function Council(props) {
	const classes = useStyles();
	const { t } = props;

	return (
		<Box className={classes.pageContainer}>
			<Header />
			<Box className={classes.contentContainer}>
				<Box className={classes.titleContainer}>
					<Breadcrumbs
						breadcrumbs={[
							{
								href: 'council',
								name: `${t('category')}\xa0 ` + ' // '
							}
						]}
					/>
				</Box>
				<Grid
					container
					direction='row'
					justify='flex-start'
					alignItems='flex-start'>
					<Grid item lg={3} xl={3} md={3} xs={12} sm={12}>
						<Box className={classes.imageContainer}>
							<img
								src='http://api.madefalva.ro/v1/api/files/img/SZCS-d7b6?size=sm'
								alt='polgarmester'
								className={classes.img}
							/>
						</Box>
					</Grid>
					<Grid item lg={9} xl={9} md={9} xs={12} sm={12}>
						<Box className={classes.desciptionContainer}>
							<Box className={classes.descriptionTitleContainer}>
								<Typography className={classes.descriptionTitle}>
									{t('descriptionTitle1')}
								</Typography>
								<Typography className={classes.descriptionTitle}>
									{t('descriptionTitle2')}
								</Typography>
							</Box>
							<Box className={classes.descriptionContainer}>
								<Typography className={classes.descriptionText}>
									{t('description')}
								</Typography>
							</Box>
						</Box>
					</Grid>
				</Grid>

				<Grid container className={classes.cardsContainer}>
					<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
						<CategoryCard type='pub_int' />
					</Grid>
					<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
						<CategoryCard type='localcouncil' />
					</Grid>
					<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
						<CategoryCard type='orgngrm' />
					</Grid>
					<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
						<CategoryCard type='soc_off' />
					</Grid>
					<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
						<CategoryCard type='registryoffice' />
					</Grid>
					<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
						<CategoryCard type='decl_avr' />
					</Grid>
					<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
						<CategoryCard type='isaticf' />
					</Grid>
					<Grid item xs={12} xl={3} md={4} sm={6} lg={3}>
						<CategoryCard type='anticorruption_startegy' />
					</Grid>
				</Grid>
			</Box>
			<Box className={classes.village2Container}>
				<Village2 />
			</Box>
		</Box>
	);
}
export default withNamespaces('council')(Council);
