/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	BrowserRouter,
	Route as BaseRoute,
	Redirect
} from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Menu from './components/routing/menus/Menu';
import { Demos } from './views/Demos';
import { Header } from './components/layout';
import { Auth } from './views/Auth';
import Route from './components/routing/Route';
import { Snackbar } from './components/snackbar';
import { Files } from './views/Files';
import { getMe } from './store/me/actions';
import { FILE_TYPE } from './store/types';
import Sights from './views/public/Sights/Sights';
import { News as PublicNews } from './views/public/News';
import { Hotel as EditHotel } from './views/Hotel';
import { Hotel as PublicHotel } from './views/public/Hotel';
import { Community as PublicCommunity } from './views/public/Community';
import { Council as PublicCouncil } from './views/public/Council';
import { LocalCouncil } from './views/public/Council/components/LocalCouncil';
import { Home as MadefalvaHome } from './views/public/madefalva/Home';
import { Home as HivatalHome } from './views/public/Home';
import { News as EditNews } from './views/News';
import { Sight as EditSight } from './views/Sight';
import { OfficialGazette as EditOfficialGazette } from './views/OfficialGazette';
import { HotelDescription } from './views/public/HotelDescription';
import { SightDescription } from './views/public/SightDescription';
import { Community as EditCommunity } from './views/Community';
import './animations.css';
import { MonumentHistory } from './views/public/MonumentHistory';
import { SiculicidiumHistory } from './views/public/SiculicidiumHistory';
import { MadefalvaHistory } from './views/public/MadefalvaHistory';
import { Lives } from './views/public/Lives';
import DrawerNavigator from './components/routing/menus/DrawerNavigator/DrawerNavigator';
import { Organigram } from './views/public/Council/components/Organigramma';
import { SocialOffice } from './views/public/Council/components/SocialOffice';
import { RegistryOffice } from './views/public/Council/components/RegistryOffice';
import { OfficialGazette as PublicOfficialGazette } from './views/public/OfficialGazette';
import Footer from './components/layout/footer/Footer';
import { NewsPage } from './views/public/News/NewsPage';
import { GazettePage } from './views/public/OfficialGazette/GazettePage';
import { EditOffice } from './views/Office';
import { EditCouncil } from './views/Council';
import { Language } from './views/public/Language';
import { cookiesAccepted } from './store/page/cookies/actions';
import { changeLanguage } from './store/page/language/actions';

import UnderConstruction from './views/public/UnderConstruction/UnderConstruction';
import { PublicInterest } from './views/public/Council/components/PublicInterest';
import { DeclaratiiAvere } from './views/public/Council/components/DeclaratiiAvere';
import Sistematic from './views/public/Council/components/Sistematic/Sistematic';
import { EditAmadeTv } from './views/AmadeTv';
import { AmadeTv } from './views/public/AmadeTv';
import { getEmptySections } from './store/home/actions';
import { AnticorruptionStartegy } from './views/public/Council/components/AnticorruptionStartegy';

const App = (props) => {
	const [drawerOpen, toggleDrawerOpen] = useState(false);
	const language = useSelector((state) => state.language.language);
	const footerRequired = useSelector((state) => state.layout.footerRequired);
	const [menuOpen, toggleMenuOpen] = useState(false);

	// eslint-disable-next-line react/prop-types
	const { page } = props;

	const tryAndGetMe = async () => {
		try {
			await getMe();
			// eslint-disable-next-line no-empty
		} catch {}
	};

	useEffect(() => {
		const lang = Cookies.get('lang');
		if (lang && lang !== 'undefined') {
			changeLanguage(lang || 'ro');
			tryAndGetMe();
			cookiesAccepted();
		} else {
			Cookies.remove('lang');
		}
	}, []);

	useEffect(() => {
		if (language) {
			document.title = language === 'hu' ? 'Madéfalva' : 'Siculeni';
		}
	}, [language]);

	useEffect(() => {
		(async () => {
			try {
				await getEmptySections();
			} catch (error) {
				console.log('Failed to load empty sections');
			}
		})();
	}, []);

	return (
		<>
			<Snackbar />
			<BrowserRouter>
				<Router>
					<Header
						drawerOpen={drawerOpen}
						toggleDrawerOpen={toggleDrawerOpen}
						page={page}
						menuOpen={menuOpen}
						toggleMenuOpen={toggleMenuOpen}
					/>
					<DrawerNavigator
						open={drawerOpen}
						toggle={toggleDrawerOpen}
						page={page}
					/>
					<Menu menuOpen={menuOpen} toggleMenuOpen={toggleMenuOpen} />
					<Switch>
						{/* <BaseRoute
							path='/v1/api/:rest'
							render={(prop) => {
								window.location = `https://api.madefalva.ro/${prop.location.pathname}`;
								return 'apple';
							}}
						/> */}
						<BaseRoute
							path='/under-construction'
							component={UnderConstruction}
						/>
						{page === 'office' && (
							<Redirect
								from={[
									'/history/madefalva',
									'/history/monument',
									'/history',
									'/home'
								]}
								to='/under-construction'
							/>
						)}
						{/* <Route name="dashboard" path="/dashboard">
							<Home />
						</Route> */}
						<Route name='demo' path='/demo'>
							<Demos />
						</Route>
						{/* <Route name="post" path="/post">
							<Post />
						</Route> */}
						<Route name='auth' path='/auth'>
							<Auth />
						</Route>
						<Route name='file' path='/file'>
							<Files type={FILE_TYPE.ATTACHMENT} />
						</Route>
						<Route name='images' path='/images'>
							<Files type={FILE_TYPE.IMAGE} />
						</Route>

						<Route name='news' path='/news/:id'>
							<NewsPage />
						</Route>
						<Route name='news' path='/news'>
							<PublicNews />
						</Route>
						<Route name='sights' path='/sights/:type'>
							<Sights />
						</Route>
						<Route name='lives' path='/lives'>
							<Lives />
						</Route>
						<Route name='sight' path='/sight/:id'>
							<SightDescription />
						</Route>
						<Route name='editsight' path='/editsight'>
							<EditSight />
						</Route>
						<Route name='editcommunity' path='/editcommunity'>
							<EditCommunity />
						</Route>
						<Route name='editHotel' path='/edithotel'>
							<EditHotel />
						</Route>
						<Route name='editoffice' path='/editoffice'>
							<EditOffice />
						</Route>
						<Route name='hotels' path='/hotels'>
							<PublicHotel />
						</Route>
						<Route name='community' path='/community'>
							<PublicCommunity />
						</Route>
						<Route name='council' path='/council'>
							<PublicCouncil />
						</Route>
						<Route name='decl_avr' path='/decl_avr'>
							<DeclaratiiAvere />
						</Route>
						<Route name='isaticf' path='/isaticf'>
							<Sistematic />
						</Route>
						<Route
							name='anticorruption_startegy'
							path='/anticorruption_startegy'>
							<AnticorruptionStartegy />
						</Route>
						<Route name='publicInterest' path='/pub_int'>
							<PublicInterest />
						</Route>
						<Route name='localCouncil' path='/localcouncil'>
							<LocalCouncil />
						</Route>
						<Route name='organigram' path='/orgngrm'>
							<Organigram />
						</Route>
						<Route name='socialoffice' path='/soc_off'>
							<SocialOffice />
						</Route>
						<Route name='registryoffice' path='/registryoffice'>
							<RegistryOffice />
						</Route>

						<Route name='madefalvahome' path='/madefalva/home'>
							<MadefalvaHome />
						</Route>
						<Route name='editnews' path='/editnews'>
							<EditNews />
						</Route>
						<Route name='editcouncil' path='/editcouncil'>
							<EditCouncil />
						</Route>
						<Route name='monumenthistory' path='/history/monument'>
							<MonumentHistory />
						</Route>
						<Route name='siculicidiumhistory' path='/history/siculicidium'>
							<SiculicidiumHistory />
						</Route>
						<Route name='madefalvahistory' path='/history/madefalva'>
							<MadefalvaHistory />
						</Route>

						<Route name='hotel' path='/hotel/:id'>
							<HotelDescription />
						</Route>
						<Route name='editofficialgazette' path='/editofficialgazette'>
							<EditOfficialGazette />
						</Route>
						<Route name='gazette' path='/gazette/:cat'>
							<GazettePage />
						</Route>
						<Route name='gazette' path='/gazette'>
							<PublicOfficialGazette />
						</Route>
						<Route name='amade-tv' path='/amade-tv'>
							<AmadeTv />
						</Route>
						<Route name='edit/amade-tv' path='/edit/amade-tv'>
							<EditAmadeTv />
						</Route>
						<Route name='language' path='/language'>
							<Language />
						</Route>

						{page === 'office' ? (
							<Route name='officehome' path='/'>
								<HivatalHome />
							</Route>
						) : (
							<Route name='madefalvahome' path='/'>
								<MadefalvaHome />
							</Route>
						)}
					</Switch>
					{footerRequired && <Footer page={page} />}
				</Router>
			</BrowserRouter>
		</>
	);
};

export default App;
