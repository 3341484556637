import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Button } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import Vintage from '../../../../components/images/vintage03.png';
import {
	ChevronRight,
	RotatedHalfCircleStripes,
	HomeScreenHalfCircle,
	Monument
} from '../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `url(${Vintage})`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'relative',
		backgroundColor: '#DDE9E6',
		// left: 318,
		left: 368,
		width: 850,
		height: 640,
		top: 96,
		// top: 0,
		[theme.breakpoints.only('xs')]: {
			width: 320,
			height: 270,
			// left: "auto",
			left: 0,
			top: 70
		},
		[theme.breakpoints.only('md')]: {
			right: -300,
			width: 700,
			height: 550,
			left: 'auto',
			top: 0
		},
		[theme.breakpoints.up('lg')]: {
			top: -4
		},
		[theme.breakpoints.only('sm')]: {
			left: 'auto',
			right: 0,
			width: 570,
			height: 450
		}
	},
	pageContainer: {
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.up('lg')]: {
			//marginTop: '-100px'
		}
	},
	contentContainer: {
		top: '0px',
		width: '100%',
		height: '869px',
		position: 'absolute',
		[theme.breakpoints.only('xs')]: {
			height: 550
		}
	},
	mainTitleBox: {
		top: '285px',
		position: 'absolute',
		background: '#FEFEFE 0% 0% no-repeat padding-box',
		opacity: 1,
		textAlign: 'right',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 250
		},
		[theme.breakpoints.only('md')]: {
			left: 30,
			top: 290
		},
		[theme.breakpoints.up('lg')]: {
			top: 185
		},
		[theme.breakpoints.only('sm')]: {
			left: 'auto',
			right: 40,
			top: 400
		}
	},
	mainTitleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 700,
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '56px',
		letterSpacing: '1px',
		marginTop: '6px',
		marginRight: '37px',
		marginBottom: '8px',
		marginLeft: '20px',
		[theme.breakpoints.only('xs')]: {
			fontSize: '34px',
			lineHeight: 'unset',
			marginRight: 20,
			marginLeft: 20
		}
	},
	animatedCircles: {
		position: 'absolute',
		zIndex: 100
	},
	halfCircle: {
		position: 'absolute',
		top: '354px',
		left: '288px',
		zIndex: 95,
		width: '192px',
		height: '96px',
		[theme.breakpoints.only('xs')]: {
			top: 260,
			width: 120,
			height: 60,
			right: 0,
			opacity: 0.5,
			left: 100
		},
		[theme.breakpoints.up('lg')]: {
			top: 254
		},
		[theme.breakpoints.only('sm')]: {
			top: 450
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '552px',
		maxWidth: 326,
		boxSizing: 'borderBox',
		paddingLeft: '20px',
		paddingRight: '20px',
		[theme.breakpoints.only('md')]: {
			left: 30,
			top: 600
		},
		[theme.breakpoints.up('lg')]: {
			top: 452
		},
		[theme.breakpoints.only('sm')]: {
			left: 40,
			height: 'fit-content',
			backgroundColor: '#DDE9E6',
			zIndex: 200
		},
		[theme.breakpoints.only('xs')]: {
			top: 360,
			left: 'unset',
			right: 0,
			width: 'unset',
			maxWidth: 280,
			textAlign: 'left',
			paddingLeft: 20
		},
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		[theme.breakpoints.only('xs')]: {
			fontSize: '16px',
			lineHeight: '20px',
			textAlign: 'right',
			paddingTop: 20,
		}
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 40,
			paddingRight: 40,
			height: 50,
		},
	},
	buttonContainer: {
		position: 'absolute',
		top: '674px',
		[theme.breakpoints.only('xs')]: {
			top: 470,
			right: 0,
			marginRight: '24px',
		},
		[theme.breakpoints.only('md')]: {
			top: 700,
			left: 30
		},
		[theme.breakpoints.up('lg')]: {
			top: 574
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	},
	footerIconBox: {
		position: 'absolute',
		bottom: '0px',
		right: 0,

		height: '191px',
		[theme.breakpoints.only('xs')]: {
			bottom: 0,
			height: 100,
			width: 200,
			left: 0
		},
		[theme.breakpoints.up('lg')]: {
			top: 'calc(100vh - 100px - 191px)'
		},
		[theme.breakpoints.only('sm')]: {
			top: 550,
			right: 40
		},
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			top: 'calc(133vh - 100px - 191px)',
			bottom: '-33vh'
		}
	},
	halfStripes: {
		position: 'absolute',
		top: '166px',
		left: '187px',
		width: '246px',
		height: '361px',
		[theme.breakpoints.only('xs')]: {
			height: 230,
			left: 0,
			opacity: 0.5,
			top: 150
		},
		[theme.breakpoints.up('lg')]: {
			top: 66
		},
		[theme.breakpoints.only('sm')]: {
			top: 270
		}
	}
}));

function MonumentHistory(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		// window.scrollTo(0, 7000);
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	console.log()
	return (
		<Box
			className={classes.pageContainer}
			style={{ zIndex: showAnimation ? 200 : 150 }}
			height={showAnimation && "100vh"}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='vintageimage3'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<Box className={classes.contentContainer}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrmiddle'
					unmountOnExit>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('monumenthistory.title')}
						</Typography>
					</Box>
				</CSSTransition>
				<Box className={classes.animatedCircles}>
					<CSSTransition
						in={showAnimation}
						timeout={300}
						classNames='rotate'
						unmountOnExit>
						<Box className={classes.halfStripes}>
							<RotatedHalfCircleStripes width='100%' height='100%' />
						</Box>
					</CSSTransition>
					<CSSTransition
						in={showAnimation}
						timeout={300}
						classNames='ltrmiddle'
						unmountOnExit>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle
								width='100%'
								height='100%'
								fill='#E0B080BF'
							/>
						</Box>
					</CSSTransition>
				</Box>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrmiddle'
					unmountOnExit>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionText} varinat='paragraph'>
							{t('monumenthistory.description')}
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrshort'
					unmountOnExit>
					<Box className={classes.buttonContainer}>
						<Button
							type='button'
							className={classes.nextButton}
							onClick={() => {
								window.scrollTo(0,0)
								props.history.push('/history/monument');
							}}>
							<Typography variant='button'>
								{t('monumenthistory.button')}
							</Typography>
							<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
						</Button>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='bttmiddle'
					unmountOnExit>
					<Box className={classes.footerIconBox}>
						<Monument height='100%' fill='#FFFFFF' dotfill='#FFFFFF' />
					</Box>
				</CSSTransition>
			</Box>
		</Box>
	);
}

export default withRouter(withNamespaces('home')(MonumentHistory));
