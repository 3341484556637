import React, { useEffect } from 'react';
import { Box, makeStyles, Typography, withWidth } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from '../../../components/image';
import { Header } from './components/header';
import {
	Village,
	HonoraryCitizens,
	Organizations,
	Church1,
	HealthCare,
	Education,
	FriendshipTowns
} from '../../../components/icons';
import { CommunityCard } from './components/CommunityCard';
import {
	getAllByCategory,
	getAllActiveCommunity,
	getElements
} from '../../../store/community/actions';
import { transformQuery } from '../../../lib/helpers/queryTransformer';

const useStyles = makeStyles((theme) => ({
	hotelTitle: {
		color: '#070C35',
		fontSize: '32px',
		fontWeight: 'bold',
		marginLeft: '365px',
		marginTop: '65px',
		marginBottom: '45px'
	},
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 54,
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	titleContainer: {
		width: '100%',
		height: 74,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 10,
		paddingTop: 60,
		borderBottom: '1px solid #D5D4C9',
		[theme.breakpoints.down('sm')]: {
			paddingTop: 30
		}
	},
	titleContainerClickable: {
		width: '100%',
		height: 74,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 10,
		paddingTop: 60,
		borderBottom: '1px solid #D5D4C9',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	titleContainerChurch: {
		width: '100%',
		height: 132,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 50,
		paddingTop: 60,
		borderBottom: '1px solid #D5D4C9'
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 31,
		paddingLeft: 10,
		paddingBottom: 50,
		[theme.breakpoints.down('md')]: {
			fontSize: '20px'
		}
	},
	churchCategoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 89,
		paddingLeft: 10,
		paddingBottom: 50
	}
}));

function Community(props) {
	const classes = useStyles();
	const { t } = props;
	const history = useHistory();

	useEffect(() => {
		getAllByCategory('ngos');
		getAllByCategory('churches');
		getAllByCategory('educationalInstitutions');
		getAllByCategory('health');
		getAllByCategory('brotherSettlements');
	}, []);

	const data = useSelector((state) => state.community.list);

	return (
		<Box className={classes.pageContainer}>
			<Header />
			<Box className={classes.contentContainer}>
				<Box
					className={classes.titleContainerClickable}
					onClick={() =>
						history.push({
							pathname: '/localcouncil',
							search: transformQuery({
								category: 'citizen'
							})
						})
					}>
					<HonoraryCitizens width={97} height={73} />
					<Typography className={classes.categoryTitle}>
						{t('citizens')}
					</Typography>
				</Box>
				{data?.ngos?.length > 0 && (
					<Box>
						<Box className={classes.titleContainer}>
							<Organizations width={97} height={73} />
							<Typography className={classes.categoryTitle}>
								{t('ngos')}
							</Typography>
						</Box>

						{data?.ngos.map((community, index) => (
							<CommunityCard
								key={index}
								communityItem={community}
								category='ngos'
							/>
						))}
					</Box>
				)}
				{data?.churches?.length > 0 && (
					<Box>
						<Box className={classes.titleContainerChurch}>
							<Church1 width={97} height={131} />
							<Typography className={classes.churchCategoryTitle}>
								{t('churches')}
							</Typography>
						</Box>

						{data?.churches.map((community, index) => (
							<CommunityCard
								key={index}
								communityItem={community}
								category='churches'
							/>
						))}
					</Box>
				)}
				{data?.educationalInstitutions?.length > 0 && (
					<Box>
						<Box className={classes.titleContainer}>
							<Education width={97} height={73} />
							<Typography className={classes.categoryTitle}>
								{t('educationalInstitutions')}
							</Typography>
						</Box>

						{data?.educationalInstitutions.map((community, index) => (
							<CommunityCard
								key={index}
								communityItem={community}
								category='educationalInstitutions'
							/>
						))}
					</Box>
				)}
				{data?.health?.length > 0 && (
					<Box>
						<Box className={classes.titleContainer}>
							<HealthCare width={97} height={73} />
							<Typography className={classes.categoryTitle}>
								{t('health')}
							</Typography>
						</Box>

						{data?.health.map((community, index) => (
							<CommunityCard
								key={index}
								communityItem={community}
								category='health'
							/>
						))}
					</Box>
				)}
				{data?.brotherSettlements?.length > 0 && (
					<Box>
						<Box className={classes.titleContainer}>
							<FriendshipTowns width={97} height={73} />
							<Typography className={classes.categoryTitle}>
								{t('brotherSettlements')}
							</Typography>
						</Box>

						{data?.brotherSettlements.map((community, index) => (
							<CommunityCard
								key={index}
								communityItem={community}
								category='brotherSettlements'
							/>
						))}
					</Box>
				)}
			</Box>
			<Village />
		</Box>
	);
}
export default withWidth()(withNamespaces('community')(Community));
