import React, { useState, useEffect } from 'react';
import {
	Typography,
	Box,
	makeStyles,
	IconButton,
	withWidth,
	Collapse,
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import HomePic from '../../../../components/images/Home_02.png';
import {
	HomeScreenHalfCircle,
	ChevronRight,
	MenuTriangleReverse,
} from '../../../../components/icons';
import { News, MonumentHistory, MadefalvaEl } from '.';
import {
	SiculicidiumHistory12,
	MadefalvaHistory2,
} from '../../History/components';
import {
	setFooterRequired,
	showLargeMenu,
} from '../../../../store/layout/actions';

const SCROLL_DEBOUNCE = 950;
let lastScrollTimestamp;
const useStyles = makeStyles((theme) => ({
	imageContainer: {
		width: '100%',
		height: '548px',
		zIndex: 1,
		background: `transparent url(${HomePic}) 0% 0% no-repeat padding-box`,
		backgroundSize: 'cover',
	},
	pageContainer: {
		position: 'relative',
		height: 2768,
		'& *': {
			MsOverflowStyle: 'none',
			scrollbarWidth: 'none',
		},
	},
	divider: {
		width: '100%',
		position: 'absolute',
		bottom: -2,
		height: 58,
	},
	headerContainer: {
		height: 548,
		position: 'relative',
	},
	titleBox: {
		width: 1128,
		position: 'absolute',
		top: 226,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	titleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: '#070C35',
		fontSize: '50px',
		letterSpacing: '-1px',
		lineHeight: '57px',
		marginTop: 6,
		marginLeft: 20,
		marginBottom: 8,
		marginRight: 30,
		zIndex: 102,
	},
	titleContainer: {
		position: 'absolute',
		top: 0,
		width: 'fit-content',
		background: '#FEFEFE',
		left: 0,
	},
	titleContainer2: {
		position: 'absolute',
		top: 85,
		width: 'fit-content',
		left: 161,
		zIndex: 2,
	},
	titleText2: {
		color: '#FAFAF5',
		fontSize: '30px',
		letterSpacing: '0',
		lineHeight: '35px',
	},
	halfCircle: {
		width: 192,
		height: 96,
		position: 'absolute',
		top: 70,
		left: 113,
		zIndex: 1,
	},
	contentContainer: {
		width: 1128,
		height: 596,
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.only('md')]: {
			width: '100%',
			marginLeft: 30,
			marginRight: 30,
		},
	},
	newsTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: '#070C35',
		fontSize: '32px',
		letterSpacing: '0px',
		lineHeight: '34px',
		marginBottom: 25,
	},
	newsDescription: {
		fontFamily: 'Ubuntu',
		color: '#070C35',
		fontSize: '14px',
		letterSpacing: '0px',
		lineHeight: '24px',
	},
	newsContainer: {
		width: '100%',
	},
	sicHistory: {
		height: '100vh',
	},
	madHistory: {
		height: '100vh',
	},
	monHistory: {
		height: '100vh',
	},

	arrowButton: {
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		left: 0,
		right: 0,
		marginTop: 0,
		zIndex: 100,
		position: 'absolute',
		top: 518,
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	navigationIconButton: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF',
		},
		transform: 'rotate(90deg)',
	},
	arrowDown: {
		background: '#070c35 0% 0% no-repeat padding-box',
		opacity: 1,
	},
	bottomDescoration: {
		height: 300,
		width: '100%',
		position: 'absolute',
		bottom: 0,
		backgroundColor:
			'transparent linear-gradient(180deg, #7993A200 0%, #3D4A51 100%) 0% 0% no-repeat padding-box',
	},
}));

function HomeAnimated(props) {
	const classes = useStyles();
	const { t } = props;

	const [show, setShow] = useState({
		home1: true,
		home2: false,
		home3: false,
		home4: false,
		home5: false,
		home6: false,
		home7: false,
	});
	// const [showSlider, setShowSlider] = useState(false);
	const largeMenu = useSelector((state) => state.layout.showLargeMenu);

	useEffect(() => {
		setShow((state) => ({ ...state, home2: true }));
		// showLargeMenu(true);
	}, []);

	useEffect(
		() => () => {
			document.body.style.overflow = 'auto';
		},
		[],
	);

	useEffect(
		() => () => {
			setFooterRequired(true);
		},
		[],
	);

	useEffect(() => {
		const { home4, home5, home6, home7 } = show;
		setFooterRequired(!(home4 || home5 || home6 || home7));
	}, [show]);

	const next = () => {
		// if (show.home1) {
		// 	showLargeMenu(false);
		//
		// 	setShow({
		// 		home1: false,
		// 		home2: true,
		// 	});
		// }

		if (show.home2) {
			setShow({ home3: true, home2: false });
		}
		if (show.home3) {
			document.body.style.overflow = 'hidden';
			setShow({ home4: true, home3: false });
		}
		if (show.home4) {
			setShow({ home5: true, home4: false });
		}
		if (show.home5) {
			setShow({ home6: true, home5: false });
		}
		if (show.home6) {
			document.body.style.overflow = 'auto';
			setShow({ home7: true, home6: false });
			// isScrollVisible()
		}
	};

	const previous = () => {
		if (show.home2) {
			setShow({
				home1: true,
				home2: true,
			});
			showLargeMenu(true);
		}
		if (show.home3) {
			setShow({
				home2: true,
				home3: false,
			});
		}
		if (show.home4) {
			document.body.style.overflow = 'visible';
			window.scrollTo(0, 600);
			setShow({ home3: true, home4: false });
		}
		if (show.home5) {
			// setShowSlider(false);
			setShow({ home4: true, home5: false });
		}
		if (show.home6) {
			// setShowSlider(false);
			setShow({ home5: true, home6: false });
		}
		if (show.home7) {
			// document.body.style.overflow = 'hidden';
			// setShowSlider(false);
			setShow({ home6: true, home7: false });
		}
	};

	const handleSwipe = (e, initialX, initialY) => {
		if (initialX === undefined || initialY === undefined) {
			return;
		}

		const currentX = e.touches[0].clientX;
		const currentY = e.touches[0].clientY;
		const diffX = initialX - currentX;
		const diffY = initialY - currentY;

		if (Math.abs(diffX) > Math.abs(diffY)) {
			return;
		}

		if (diffY > 0) {
			next(); // swipe up
		} else {
			previous(); // swipe down
		}
	};

	// tslint:disable-next-line: no-any
	const handleWheel = (event) => {
		event.stopPropagation();
		if (Date.now() - lastScrollTimestamp < SCROLL_DEBOUNCE) {
			return;
		}

		lastScrollTimestamp = Date.now();
		const delta = Math.sign(event.deltaY);
		if (show.home7) {
			if (window.scrollY === 0 && delta === -1) {
				previous();
			}
		} else if (delta === 1) {
			showLargeMenu(false);
			if (window.scrollY > 500) {
				next();
			}
			// 	if (
			// 	(largeMenu && window.scrollY > 300) ||
			// 	(!largeMenu && window.scrollY > 500)
			// ) {
			// 	next();
			// }
		} else if (delta === -1) {
			previous();
		}
	};

	// tslint:disable-next-line: no-any
	const handleKey = (e) => {
		switch (e.code) {
			case 'ArrowDown':
				next();
				break;
			case 'ArrowUp':
				previous();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		let initialX;
		let initialY;

		const startTouch = (e) => {
			initialX = e.touches[0].clientX;
			initialY = e.touches[0].clientY;
		};

		const moveTouch = (e) => {
			/* if (visible) {
                e.preventDefault();
                initialX = undefined;
                initialY = undefined;

                return;
            } */

			handleSwipe(e, initialX, initialY);
			initialX = undefined;
			initialY = undefined;
		};

		window.addEventListener('wheel', handleWheel);
		window.addEventListener('keyup', handleKey);
		window.addEventListener('touchstart', startTouch, false);
		window.addEventListener('touchmove', moveTouch, { passive: false });

		return () => {
			window.removeEventListener('wheel', handleWheel);
			window.removeEventListener('keyup', handleKey);
			window.removeEventListener('touchstart', startTouch);
			window.removeEventListener('touchmove', moveTouch);
		};
	}, [show]);

	return (
		<Box
			className={classes.pageContainer}
			style={{
				zIndex: show.home1 || show.home2 || show.home3 ? 200 : 150,
			}}>
			<Collapse in={largeMenu} timeout={600}>
				<Box style={{ height: 260 }} />
			</Collapse>
			{(show.home || show.home1 || show.home2 || show.home3) && (
				<Box>
					<Box className={classes.headerContainer}>
						<Box className={classes.imageContainer} />

						<Box className={classes.titleBox}>
							<Box className={classes.titleContainer}>
								<Typography className={classes.titleText}>
									{t('titleText1')}
								</Typography>
							</Box>
							<Box className={classes.titleContainer2}>
								<Typography className={classes.titleText2}>
									{t('titleText2')}
								</Typography>
							</Box>
							<Box className={classes.halfCircle}>
								<HomeScreenHalfCircle
									width='100%'
									height='100%'
									fill='#070C35'
								/>
							</Box>
						</Box>
						<Box className={classes.bottomDescoration}>
							<Box className={classes.divider}>
								<MenuTriangleReverse
									width='100%'
									height='100%'
									fill='#F0F0EB'
								/>
							</Box>
						</Box>
						<Box className={classes.arrowButton}>
							<IconButton
								className={classes.navigationIconButton}
								onClick={() => {
									next();
								}}>
								<ChevronRight
									width='24px'
									height='24px'
									fill='#070C35'
									className={classes.arrowDown}
								/>
							</IconButton>
						</Box>
					</Box>

					{(show.home2 || show.home3) && (
						<Box className={classes.contentContainer}>
							<Box className={classes.newsContainer}>
								<News />
							</Box>
						</Box>
					)}
				</Box>
			)}
			<SiculicidiumHistory12 showAnimation={show.home4} />
			<MadefalvaHistory2 showAnimation={show.home5} />
			<MonumentHistory showAnimation={show.home6} />
			<MadefalvaEl showAnimation={show.home7} />
		</Box>
	);
}

export default withWidth()(withNamespaces('home')(HomeAnimated));
