import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	CardActionArea,
	CardActions,
	CardMedia,
	Button,
	Card,
	Checkbox,
	Box,
	Typography
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles({
	root: {
		width: '100%'
	},
	media: {
		height: 200
	}
});

function File(props) {
	const { t, file, selector, selectItem } = props;
	const classes = useStyles();

	const handleSelect = () => {
		selectItem(file);
	};

	return (
		<Card className={classes.root}>
			<CardActionArea>
				<CardMedia
					className={classes.media}
					image='https://cdn.pixabay.com/photo/2013/07/12/12/41/text-146110_960_720.png'
					onClick={(event) => {
						if (selector) {
							handleSelect();
						} else {
							window.open(file.url, '_blank');
						}
					}}
				/>
			</CardActionArea>
			<CardActions>
				<Box style={{ display: 'flex', flexDirection: 'column' }}>
					<Typography>
						{file.url.substring(file.url.lastIndexOf('/') + 1, file.url.length)}
					</Typography>
					<Typography color='textSecondary'>
						{moment(file.createdAt).format('YYYY-MM-DD HH:mm:ss')}
					</Typography>
				</Box>
			</CardActions>
		</Card>
	);
}

export default withNamespaces()(File);
