import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Button } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../components/images/vintage03.png';
import {
	ChevronRight,
	RotatedHalfCircleStripes,
	HomeScreenHalfCircle,
	Monument
} from '../../../../components/icons';
import { livePage } from '../../../../config/locations';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `url(${Vintage})`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'relative',
		backgroundColor: '#F0F0EB',
		left: 368,
		width: 850,
		height: 640,
		top: 0,
		[theme.breakpoints.only('xs')]: {
			width: 320,
			height: 270,
			left: 'auto',
			left: 0,
			top: 70
		},
		[theme.breakpoints.only('md')]: {
			position: 'absolute',
			right: 0,
			left: 'auto',
			top: 0,
			width: 650,
			height: 500
		},
		[theme.breakpoints.only('sm')]: {
			position: 'absolute',
			right: 30,
			left: 'auto',
			top: 0,
			width: 600,
			height: 500
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute'
		},
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		},
		[theme.breakpoints.only('md')]: {
			width: '100%'
		},
		[theme.breakpoints.only('lg')]: {
			top: 50
		},
		[theme.breakpoints.only('xl')]: {
			top: 100
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%'
		}
	},
	contentContainer: {
		top: '0px',
		width: '100%',
		height: '100%',
		position: 'absolute',
		[theme.breakpoints.only('xs')]: {
			height: 550
		}
	},
	mainTitleBox: {
		top: '188px',
		position: 'absolute',
		background: '#FEFEFE 0% 0% no-repeat padding-box',
		textAlign: 'right',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 250
		},
		[theme.breakpoints.only('md')]: {
			left: 30,
			top: 188
		}
	},
	mainTitleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 700,
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '56px',
		letterSpacing: '1px',
		marginTop: '6px',
		marginRight: '37px',
		marginBottom: '8px',
		marginLeft: '20px',
		[theme.breakpoints.only('xs')]: {
			fontSize: '36px',
			lineHeight: '40px',
			marginRight: 20,
			marginLeft: 20
		}
	},
	animatedCircles: {
		position: 'absolute',
		zIndex: 100
	},
	halfCircle: {
		position: 'absolute',
		top: '258px',
		left: '288px',
		zIndex: 95,
		width: '192px',
		height: '96px',
		[theme.breakpoints.only('xs')]: {
			top: 260,
			width: 120,
			height: 60,
			right: 0,
			opacity: 0.5,
			left: 100
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '552px',
		width: 326,
		left: 72,
		[theme.breakpoints.only('xs')]: {
			top: 360,
			width: '100%',
			textAlign: 'left',
			left: 0
		},
		[theme.breakpoints.only('md')]: {
			left: 30,
			top: 600
		},
		[theme.breakpoints.only('lg')]: {
			top: 452
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		paddingLeft: '24px',
		[theme.breakpoints.only('xs')]: {
			// fontSize: '16px',
			// lineHeight: '20px',
			textAlign: 'right',
			paddingTop: 10,
			paddingRight: 20
		}
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		transition: '0.1s all ease',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 40,
			paddingRight: 40
		},
		"&:hover": {
			backgroundColor: "#48B3C9",
			paddingRight: 70,
			paddingLeft: 70,
			marginLeft: -10
		}
	},
	buttonContainer: {
		position: 'absolute',
		top: '674px',
		left: 72,
		[theme.breakpoints.only('xs')]: {
			top: 470,
			right: 30,
			left: 'auto'
		},
		[theme.breakpoints.only('md')]: {
			top: 700,
			left: 30
		},
		[theme.breakpoints.only('lg')]: {
			top: 576
		}
	},
	footerIconBox: {
		position: 'absolute',
		right: 0,
		top: 581,
		height: '191px',
		[theme.breakpoints.only('xs')]: {
			top: 431,
			height: 150,
			width: 200,
			left: 0
		},
		[theme.breakpoints.only('lg')]: {
			top: 530
		}
	},
	halfStripes: {
		position: 'absolute',
		top: '70px',
		left: '187px',
		width: '246px',
		height: '361px',
		[theme.breakpoints.only('xs')]: {
			height: 230,
			left: 0,
			opacity: 0.5,
			top: 150
		}
	},
	ahref: {
		textDecoration: 'none'
	}
}));

function MonumentHistory(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		// window.scrollTo(0, 7000);
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<Box
			className={classes.pageContainer}
			style={{ zIndex: showAnimation ? 1 : 0 }}>
			<CSSTransition
				in={showAnimation}
				timeout={600}
				classNames='vintageimage3'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<Box className={classes.contentContainer}>
				<CSSTransition
					in={showAnimation}
					timeout={600}
					classNames='ltrmiddle'
					unmountOnExit>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('monumenthistory.title')}
						</Typography>
					</Box>
				</CSSTransition>
				<Box className={classes.animatedCircles}>
					<CSSTransition
						in={showAnimation}
						timeout={600}
						classNames='rotate'
						unmountOnExit>
						<Box className={classes.halfStripes}>
							<RotatedHalfCircleStripes width='100%' height='100%' />
						</Box>
					</CSSTransition>
					<CSSTransition
						in={showAnimation}
						timeout={600}
						classNames='ltrmiddle'
						unmountOnExit>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle
								width='100%'
								height='100%'
								fill='#E0B080BF'
							/>
						</Box>
					</CSSTransition>
				</Box>
				<CSSTransition
					in={showAnimation}
					timeout={600}
					classNames='ltrmiddle'
					unmountOnExit>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionText} varinat='paragraph'>
							<Trans i18nKey='monumenthistory.descriptionoffice'>
								<br />
								<br />
							</Trans>
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={600}
					classNames='ltrshort'
					unmountOnExit>
					<Box className={classes.buttonContainer}>
						<a
							target='_blank'
							href={`${livePage}/history/monument`}
							className={classes.ahref}>
							<Button type='button' className={classes.nextButton}>
								<Typography variant='button'>
									{t('monumenthistory.button')}
								</Typography>
								<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
							</Button>
						</a>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={600}
					classNames='bttmiddle'
					unmountOnExit>
					<Box className={classes.footerIconBox}>
						<Monument height='100%' fill='#FFFFFF' dotfill='#FFFFFF' />
					</Box>
				</CSSTransition>
			</Box>
		</Box>
	);
}

export default withNamespaces('home')(MonumentHistory);
