import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import YouTube from 'react-youtube';
import FacebookPlayer from 'react-facebook-player';
import { FileSelector, ImageSelector } from '../../../components/modals';
import {
	Select,
	TextField,
	RichTextEditor,
	Slider
} from '../../../components/form';
import EnableRow from './EnableRow';
import Gallery from '../../../components/form/Gallery';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw'
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px'
	}
}));

export default withNamespaces(['common', 'sight'])((props) => {
	const { item, open, setOpen, onConfirm, t } = props;

	const createYoutubeIdFromUrl = (url) => {
		const videoId = url.match(
			/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
		);
		if (videoId != null) {
			return videoId[1];
		}
		return null;
	};
	const createFacebookIdFromUrl = (url) => {
		const videoId = url.match(
			/(?:(?:https?:)?\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9\.]+\/videos\/(?:[a-zA-Z0-9\.]+\/)?([0-9]+)/
		);
		if (videoId != null){
			return videoId[1];
		}
		return null;

	}

	const overrideValues = (values) => {
		values.content.forEach((item) => {
			if (!item.headerImageId) {
				item.headerImageId = item.headerImage.id;
				delete item.headerImage;
			}
			if (!item.attachmentId && item.attachment && item.attachment.id) {
				item.attachmentId = item.attachment.id;
				delete item.attachment;
			}
		});

		let content = values.content.map((item) => {
			if (item.youtubeId) {
				return { ...item, youtubeId: createYoutubeIdFromUrl(item.youtubeId) };
			}
			return { ...item, youtubeId: '' };

			// return item;
		});
		content = values.content.map((item) => {
			if (item.facebookId) {
				return { ...item, facebookId: createFacebookIdFromUrl(item.facebookId) };
			}
			return item;
		});

		const sliderContent = values.slider.content.filter(
			(item) => item.fileId !== null || item.file.id !== null
		);

		values.slider.content.forEach((item) => {
			item.fileId = item.file.id;
		});

		const galleryContent = values.gallery.content.filter(
			(item) => item.fileId !== null || (item.file && item.file.id !== null)
		);

		values.gallery.content.forEach((item) => {
			if (item.file) item.fileId = item.file.id;
		});

		const tmp = values;
		tmp.slider.content = sliderContent;
		tmp.gallery.content = galleryContent;

		return {
			category: values.category,
			slider: tmp.slider,
			gallery: tmp.gallery,
			content
		};
	};

	const classes = useStyles();
	return (
		<Formik
			enableReinitialize
			initialValues={{
				content: item
					? item.content
					: [
							{
								lang: 'hu'
							},
							{
								lang: 'ro'
							}
					  ],
				category: item ? item.category : 'events',
				slider: item
					? item.slider
					: {
							content: [
								{
									num: 1,
									fileId: null
								},
								{
									num: 2,
									fileId: null
								},
								{
									num: 3,
									fileId: null
								},
								{
									num: 4,
									fileId: null
								},
								{
									num: 5,
									fileId: null
								},
								{
									num: 6,
									fileId: null
								}
							]
					  },
				gallery:
					item && item.gallery
						? item.gallery
						: { content: [{ fileId: null, num: 1 }] }
			}}
			onSubmit={(values) => {
				onConfirm(overrideValues(values));
				setOpen(false);
			}}>
			{({
				values,
				errors,
				touched,
				handleReset,
				setFieldValue,
				submitForm
			}) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>
						{item?.content[0]?.title ? item?.content[0]?.title : 'Új elem'}
					</DialogTitle>
					<DialogContent>
						<EnableRow
							active={item.active}
							itemId={item.id}
							onFinish={() => setOpen(false)}
						/>
						<Form>
							<Field
								name='category'
								label={t('category')}
								options={[
									{
										value: 'events',
										label: t('common:newsCreateCategoryEvents')
									},
									{
										value: 'announcements',
										label: t('common:newsCreateCategoryAnnouncements')
									},
									{
										value: 'communications',
										label: t('common:newCreateCategoryCommunications')
									}
								]}
								component={Select}
								size='small'
								value={values.category}
								onChange={(evt) => setFieldValue('category', evt.target.value)}
							/>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((item, index) => {
											const validYoutubeLink =
												item.youtubeId &&
												createYoutubeIdFromUrl(item.youtubeId);
											const validFacebookLink = 
												item.facebookId && createFacebookIdFromUrl(item.facebookId);
											return (
												<Box key={index} className={classes.fieldArrayItem}>
													<Typography variant='h2'>
														{t(`common:${item.lang}`)}
													</Typography>
													<Field
														name={`content[${index}].title`}
														label={t('common:newsCreateTitle')}
														component={TextField}
													/>
													<Field
														name={`content[${index}].shortDesc`}
														label={t('common:newsCreateShortDesc')}
														component={TextField}
														multiline
														rows={2}
													/>
													<Field
														name={`content[${index}].youtubeId`}
														label={t('common:youtubeId')}
														component={TextField}
													/>
													{validYoutubeLink ? (
														<YouTube
															videoId={createYoutubeIdFromUrl(item.youtubeId)}
															id={createYoutubeIdFromUrl(item.youtubeId)}
														/>
													) : (
														<p>{t('common:youtubeLinkIsInvalid')}</p>
													)}
													<Field
														name={`content[${index}].facebookId`}
														label={t('common:facebookId')}
														component={TextField}
													/>
													{validFacebookLink ? (
														<FacebookPlayer
															videoId={createFacebookIdFromUrl(item.facebookId)}
															appId = {361360825797642}
														/>
													) : (
														<p>{t('common:facebookLinkIsInvalid')}</p>
													)}
													<Field
														name={`content[${index}].content`}
														component={RichTextEditor}
														defaultValue={item.content}
														size='small'
														label={t('common:newsCreateContent')}
													/>

													<ImageSelector
														fieldName={`content[${index}].headerImageId`}
														image={values.content[index].headerImage}
														setImageId={(id) => {
															console.log(id);
															setFieldValue(
																`content[${index}].headerImageId`,
																id
															);
														}}
													/>

													<Box
														style={{
															marginTop: 27,
															display: 'flex',
															flexDirection: 'column'
														}}>
														Csatolmany
														<FileSelector
															fieldName={`content[${index}].attachmentId`}
															file={values.content[index].attachment}
															setFileId={(id) => {
																setFieldValue(
																	`content[${index}].attachmentId`,
																	id
																);
															}}
														/>
													</Box>
												</Box>
											);
										})}
									</Box>
								)}
							/>
							<Typography variant='h2'>{t('sliderimages')}</Typography>
							<Slider values={values.slider} setFieldValue={setFieldValue} />

							<Typography variant='h2'>{t('gallery')}</Typography>
							<Gallery values={values.gallery} setFieldValue={setFieldValue} />
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='default'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='secondary'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
