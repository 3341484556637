import store from '../store';
import { GET_ALL_NEWS } from '../types';
import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';

export const getAllNews = async (params) => {
	await api.get(`news${params}`).then((response) => {
		if (response.data) {
			const news = response.data;
			const oldItems = news.items;
			const newItems = oldItems.filter(
				(newsLine) => newsLine.content.length >= 1
			);
			news.items = newItems;
			store.dispatch({
				type: GET_ALL_NEWS,
				payload: news
			});
		}
	});
};

export const getElements = (other) => {
	api.get(other ? `/news${other}` : '/news?active=true').then((response) => {
		store.dispatch({ type: GET_ALL_NEWS, payload: response.data });
	});
};

export const saveElement = async (data) =>
	api
		.post('/news', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getElements('?active=false');
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
			return false;
		});

export const updateElement = (id, data) => {
	api
		.put(`/news/${id}`, data)
		.then(() => getElements('?active=false'))
		.catch((e) => console.log(e));
};

export const deleteElement = (id) => {
	api.delete(`/news/${id}`).then(() => getElements('?active=false'));
};

export const activateElement = (id, status) => {
	api
		.patch(`/news/${id}?active=${!!status}`)
		.then(() => getElements('?active=false'));
};
